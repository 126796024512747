<template>
    <!--体检-->
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
                <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
                    <el-form-item label="姓名:">
                        <el-input type="text" v-model="selectForm.Name"></el-input>
                    </el-form-item>
                    <el-form-item label="人员状态:">
                        <el-select v-model="selectForm.State" clearable>
                            <el-option label="试用" value="试用"></el-option>
                            <el-option label="转正" value="转正"></el-option>
                            <el-option label="离职 " value="离职"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="体检类型:">
                        <el-select v-model="selectForm.Category" clearable>
                            <el-option label="岗前体检" value="岗前体检"></el-option>
                            <el-option label="岗中体检" value="岗中体检"></el-option>
                            <el-option label="转岗体检" value="转岗体检"></el-option>
                            <el-option label="离岗体检" value="离岗体检"></el-option>
                            <el-option label="个人体检" value="个人体检"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="接害岗位:">
                        <el-select v-model="selectForm.Jeopardize" clearable>
                            <el-option label="是" value="True"></el-option>
                            <el-option label="否" value="False"></el-option>
                        </el-select>
                    </el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <el-table :data="dataList" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
                <el-table-column prop="RowIndex" label="序号" width="50" align="center"> </el-table-column>
                <el-table-column prop="UserName" label="姓名"> </el-table-column>
                <el-table-column prop="Factory" label="公司"> </el-table-column>
                <el-table-column prop="DptName" label="部门"> </el-table-column>
                <el-table-column prop="PostName" label="岗位"> </el-table-column>
                <el-table-column prop="CheckupDate" label="体检时间" :formatter="formatStartTime"></el-table-column>
                <el-table-column prop="Category" label="体检类型"> </el-table-column>
                <el-table-column prop="State" label="人员状态"> </el-table-column>
                <el-table-column prop="Result" label="体检结果" width="100" filter-placement="bottom-end">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.Result === true ? 'success':'warning'"
                                disable-transitions>{{scope.row.Result? `正常`:`异常`}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="Jeopardize" label="接害岗位" width="100" filter-placement="bottom-end">
                    <template slot-scope="scope">
                        <el-tag disable-transitions>{{scope.row.Jeopardize? `是`:`否`}}</el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="ReResult" label="是否复查" width="100" filter-placement="bottom-end">
                    <template slot-scope="scope">
                        <el-tag disable-transitions>{{scope.row.ReResult? `是`:`否`}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="ReviewResult" label="复查结果" width="100" filter-placement="bottom-end">
                    <template slot-scope="scope">
                        <el-tag disable-transitions>{{scope.row.ReviewResult? `异常`:`正常`}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="ReviewResult2" label="处理结果"> </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                       layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
        </el-pagination>
        <checkupModal ref="checkupModal" @callback="getData"></checkupModal>
    </section>
    
</template>

<script>
    import util from "../../../util/date";
    import Toolbar from "../../components/newToolbar";
    import { getButtonList } from "../../promissionRouter";
    import checkupModal from "../componentModals/checkupModal"    // 体检
    import {
        GetCheckupInfoPageList
    } from "../../api/hr";
    export default {
        components: { Toolbar, checkupModal },
        data() {
            return {
                selectForm: {}, // 筛选项
                currentRow: {}, // 选中行
                buttonList: [],
                searchVal: "",
                buttonListmsg: "",

                currentPage: 1, // 当前页码
                pageSize: 20, // 每页的数据条数
                total: null, // 总条数
                totaldata: null,

                loading: true,
                page: 1,
                dataList: [], // 列表数据
            };
        },
        methods: {
            callFunction(item) {
                console.log("callFunction:", item)
                this[item.Func].apply(this, item);
            },

            selectCurrentRow(val) {
                this.currentRow = val;
            },
            // 添加
            handleAdd() {
                console.log("vue:",this)
                this.$refs.checkupModal.operation = true; // 添加
                this.$refs.checkupModal.optType = true;   // 编辑
                this.$refs.checkupModal.onShowDlg();
            },
            // 查看
            handleShow() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的一行数据！",
                        type: "error",
                    });
                    return;
                }

                this.$refs.checkupModal.operation = false; // 编辑
                this.$refs.checkupModal.optType = false;   // 查看
                this.$refs.checkupModal.onShowDlg(row.Id);
            },
            // 编辑
            handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的一行数据！",
                        type: "error",
                    });
                    return;
                }

                this.$refs.checkupModal.operation = false; // 编辑
                this.$refs.checkupModal.optType = true;    // 编辑
                this.$refs.checkupModal.onShowDlg(row.Id);
            },
            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd");
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.page = val;
                this.getData(false);
            },
            
            //获取列表
            getData(isCurrent = true) {
                if (isCurrent) {
                    this.page = 1;
                }
                let para = {
                    page: this.page,
                    size: this.pageSize,
                    userName: this.selectForm.Name,
                    State: this.selectForm.State,
                    Category: this.selectForm.Category,
                    Jeopardize: this.selectForm.Jeopardize,
                };
                console.log(para);
                GetCheckupInfoPageList(para).then((res) => {
                    console.log(res);
                    this.total = res.data.response.pageCount;
                    this.totaldata = res.data.response.dataCount;
                    this.dataList = res.data.response.data;
                    this.loading = false;
                });
            },
            getButtonList2(routers) {
                let _this = this;
                routers.forEach((element) => {
                    let path = this.$route.path.toLowerCase();
                    if (element.path && element.path.toLowerCase() == path) {
                        _this.buttonList = element.children;
                        return;
                    } else if (element.children) {
                        _this.getButtonList(element.children);
                    }
                });
            }
        },
        mounted() {
            this.getData();
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            let buttons = window.localStorage.buttList
                ? JSON.parse(window.localStorage.buttList)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers, buttons);
            console.log("this.buttonList", this.buttonList)
        },
    };
</script>

<style scoped>
</style>